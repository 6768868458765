import { Breadcrumbs } from '../components/layout';
import { Search, SelectOptions } from '../components/generic';
import ProductListWrapper from '../components/products/ProductListWrapper';
import { ProductCardUniversal as ProductCard } from '../components/products/ProductCardUniversal';
import { useProductList } from '../hooks';
import { Sidebar } from '../components/category/Sidebar';
import { CallToAction } from '../components/generic/landing/CallToAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { CategoryProductFilter } from '../components/category/CategoryProductFilter';
import { ErrorSection } from '../components/main-page/ErrorSection';
import { Loader } from '../components/generic/Loader';
import { Product } from '../state/models';
import { GenericListWrapper } from '../components/generic/landing/GenericListWrapper';
import { EmptyCategories } from '../components/main-page/EmptyCategories';
import { shownProductsPerPageInCategoryPage } from '../appconfig';
import { useState } from 'react';
import { ShowMoreButtons } from '../components/main-page/ShowMoreButtons';
import { useAnchor } from '../components/generic/useAnchor';
import { includeIgnoreCase } from '../util';

const categoryList: SelectOptions[] = [
  { id: '1', value: 'Adalo', label: 'Adalo' },
  { id: '2', value: 'Marketing tool', label: 'Marketing tool' },
  {
    id: '3',
    value: 'Content creator and viewer',
    label: 'Content creator and viewer',
  },
  { id: '4', value: 'Device', label: 'Device' },
  { id: '5', value: 'Other', label: 'Other' },
  { id: '6', value: '', label: 'All components' },
];
const crumbs = [
  { label: 'Home', to: '/' },
  { label: 'Category', to: '/category' },
];
const advanseCategory = {
  marketing: [
    'AppsFlyer SDK`s in-app events tracking',
    'Facebook SDK`s in-app events tracking',
    'Customer.io push notification',
  ],
  contentCreatorAndViewe: [
    'Document Viewer',
    'PDF Generator',
    'Simple web image editor',
    'Vertical Image list',
  ],
  device: [
    'Audio Recorder',
    'Native Photo Camera',
    'Adalo Bluetooth printer',
    'NFC Background Reader',
    'NFC Reader',
    'Camera',
    'Native Video Camera',
    'File Downloader',
    'Native Video Player (HLS/M3u8) with Web supports',
  ],
};

function productIncludes (searchTerm: string, product: Product) {
  return (
    includeIgnoreCase(product.name, searchTerm) ||
    includeIgnoreCase(product.searchString, searchTerm) ||
    includeIgnoreCase(product.description, searchTerm)
  );
}
function productsFilterbyCategory (category: string, product: Product) {
  switch (category) {
    case 'Marketing tool':
      return advanseCategory.marketing.includes(product.title);
    case 'Content creator and viewer':
      return advanseCategory.contentCreatorAndViewe.includes(product.title);
    case 'Device':
      return advanseCategory.device.includes(product.title);
    case 'Other':
      return (
        !advanseCategory.device.includes(product.title) &&
        !advanseCategory.contentCreatorAndViewe.includes(product.title) &&
        !advanseCategory.marketing.includes(product.title) &&
        !productIncludes(category, product)
      );
    default:
      return productIncludes(category, product);
  }
}
export const CategoryPage = () => {
  const { products, loading, error } = useProductList({ status: 'all' });
  const [chunkIndex, setChunkIndex] = useState(
    shownProductsPerPageInCategoryPage
  );
  const { anchor, scrollToAnchor } = useAnchor();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get('search');
  const category = searchParams.get('category');

  const crumbsFromUrl = category
    ? {
      label: category,
      to: location.pathname + location.search,
    }
    : null;

  const shownCrumbs = [...crumbs];
  crumbsFromUrl && shownCrumbs.push(crumbsFromUrl);

  const updateSearchTerm = (v: string) => {
    const search = new URLSearchParams(location.search);
    search.set('search', v);
    // VERY EXPENSIVE VERY VERY
    if (!v) {
      search.delete('search');
      navigate(
        category
          ? location.pathname + `?category=${category}`
          : location.pathname
      );
      return;
    }

    navigate({ search: search.toString() });
  };

  // ADDD FILTERING BY TYPE
  const productsByType = category
    ? products.filter((product) =>
      productsFilterbyCategory(category ?? '', product)
    )
    : products;

  const productsBySearchTerm = searchValue
    ? productsByType.filter((product) =>
      productIncludes(searchValue ?? '', product)
    )
    : productsByType;
  const isVisibleButton =
    productsBySearchTerm.length > shownProductsPerPageInCategoryPage;
  const visibleProducts = productsBySearchTerm.slice(0, chunkIndex);
  const allShown = productsBySearchTerm.length <= visibleProducts.length;

  const loadMore = (extra: number) => setChunkIndex((prev) => prev + extra);
  const loadMoreComponents = () => loadMore(shownProductsPerPageInCategoryPage);
  const showDefaultComponents = () => {
    setChunkIndex(shownProductsPerPageInCategoryPage);
    scrollToAnchor();
  };

  return (
    <>
      {anchor}
      <div>
        <div className='flex justify-center'>
          <Breadcrumbs crumbs={shownCrumbs} />
        </div>

        <div className='lg:flex xl:mx-auto xl:max-w-[810px] 2xl:max-w-[1100px] '>
          <Sidebar categoryList={categoryList} />

          <div className='h-fit flex-grow md:overflow-y-auto'>
            <div className='mb-3'>
              <Search value={searchValue ?? ''} onChange={updateSearchTerm} />
            </div>
            <div className='xl:hidden'>
              <CategoryProductFilter categoryList={categoryList} />
            </div>

            <div className='flex flex-col items-center sm:block'>
              <GenericListWrapper
                error={error}
                errorComponent={<ErrorSection />}
                loadingComponent={<Loader />}
                loading={loading}
              >
                <>
                  {visibleProducts.length ? (
                    <>
                      <ProductListWrapper className='xl:grid-cols-2 2xl:grid-cols-3'>
                        {visibleProducts.map((product) => (
                          <ProductCard key={product.id} product={product} />
                        ))}
                      </ProductListWrapper>
                      {isVisibleButton && (
                        <ShowMoreButtons
                          loadMore={loadMoreComponents}
                          resetToDefault={showDefaultComponents}
                          allShown={allShown}
                        />
                      )}
                    </>
                  ) : (
                    <EmptyCategories />
                  )}
                </>
              </GenericListWrapper>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
    </>
  );
};
