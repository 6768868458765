import { ColorPageHeader } from '../components/generic/header/ColorPageHeader';
import Section from '../components/generic/text-format/Section';
import Paragraph from '../components/generic/text-format/Paragraph';
import StyledList from '../components/generic/text-format/StyledList';
import StrongText from '../components/generic/text-format/StrongText';
import { CallToAction } from '../components/generic/landing/CallToAction';

export const PrivacyPage = () => {
  return (
    <div className='w-full'>
      <ColorPageHeader
        mainHeader='Privacy'
        secondaryHeader='Discover how we handle your personal information securely'
        showSearch
      />
      <div className='p-2 sm:p-10 md:p-6 max-w-screen-xl m-auto'>
        <Section title='Contact Information'>
          <Paragraph>
            <StrongText>Company Name: </StrongText>Cosmith
          </Paragraph>
          <Paragraph>
            <StrongText>Address: </StrongText>Ukraine
          </Paragraph>
          <Paragraph>
            <StrongText>Email: </StrongText>
            <a
              href='mailto:info@cosmith.io'
              className='text-blue-600 focus:outline focus-within:outline-2 focus-within:outline-cosmith-lavanda-500 rounded-sm'
            >
              info@cosmith.io
            </a>
          </Paragraph>
        </Section>

        <Section title='Types of Personal Data Collected'>
          <Paragraph>
            We may collect the following types of personal data:
          </Paragraph>
          <StyledList
            items={[
              'Contact details (e.g., name, email address, phone number)',
              'Demographic information (e.g., age, gender, preferences)',
              'Payment information (e.g., credit card details, billing address)',
              'User activity data (e.g., website usage, preferences, IP address)',
              'Any other information you choose to provide',
            ]}
          />
        </Section>

        <Section title='Sources of Data'>
          <Paragraph>
            We primarily collect data directly from you when you:
          </Paragraph>
          <StyledList
            items={[
              'Fill out forms on our website',
              'Sign up for our services or newsletters',
              'Contact us via email, phone, or other channels',
              'Make a purchase or complete a transaction',
            ]}
          />
          <Paragraph>
            If we obtain your personal data from a third party, we will inform
            you of the source and type of data collected at the time of first
            contact.
          </Paragraph>
        </Section>

        <Section title='Purpose and Use of Collected Data'>
          <Paragraph>
            We use your personal data for the following purposes:
          </Paragraph>
          <StyledList
            items={[
              'To provide and manage our services',
              'To improve our website and user experience',
              'To process payments and transactions',
              'To communicate with you regarding your account or any services you have requested',
              'To send you promotional materials, if you have opted in to receive them',
            ]}
          />
        </Section>

        <Section title='Lawful Basis for Data Processing'>
          <Paragraph>
            Our processing of your personal data is based on the following
            lawful bases:
          </Paragraph>
          <StyledList
            items={[
              <span>
                <StrongText>Consent: </StrongText>Where you have given explicit
                consent for specific uses of your data.
              </span>,
              <span>
                <StrongText>Contract: </StrongText>To fulfill contractual
                obligations, such as processing payments or delivering services.
              </span>,
              <span>
                <StrongText>Legal Obligation: </StrongText>Compliance with
                applicable legal or regulatory requirements.
              </span>,
              <span>
                <StrongText>Legitimate Interests: </StrongText>We may process
                your data where it is necessary for our legitimate interests and
                where those interests are not overridden by your data protection
                rights.
              </span>,
            ]}
          />
        </Section>

        <Section title='Data Sharing'>
          <Paragraph>We may share your personal data with:</Paragraph>
          <StyledList
            items={[
              'Service providers who perform functions on our behalf (e.g., payment processors, marketing agencies)',
              'Legal authorities, if required by law',
              'Business partners in the context of a merger, acquisition, or sale of all or part of our business',
            ]}
          />
          <Paragraph>
            We will not sell, rent, or trade your personal data with third
            parties without your consent.
          </Paragraph>
        </Section>

        <Section title='Data Retention'>
          <Paragraph>
            We retain personal data only as long as necessary for the purposes
            described in this policy or as required by law. After the retention
            period expires, we will securely delete or anonymize your personal
            data.
          </Paragraph>
        </Section>

        <Section title='Your Rights'>
          <Paragraph>
            You have the following rights regarding your personal data:
          </Paragraph>
          <StyledList
            items={[
              <span>
                <StrongText>Right to Access: </StrongText>Request access to the
                personal data we hold about you.
              </span>,
              <span>
                <StrongText>Right to Rectification: </StrongText>Request
                correction of inaccurate or incomplete data.
              </span>,
              <span>
                <StrongText>Right to Erasure: </StrongText>Request deletion of
                your personal data under certain conditions.
              </span>,
              <span>
                <StrongText>Right to Restrict Processing: </StrongText>Request
                restriction of processing under certain conditions.
              </span>,
              <span>
                <StrongText>Right to Data Portability: </StrongText>Request
                transfer of your data to another organization.
              </span>,
              <span>
                <StrongText>Right to Withdraw Consent: </StrongText>Withdraw
                consent at any time, where processing is based on consent.
              </span>,
            ]}
          />
        </Section>

        <Section title='How to Make a Complaint'>
          <Paragraph>
            If you have concerns about our use of your personal data, you can
            contact us at{' '}
            <a
              href='mailto:info@cosmith.io'
              className='text-blue-600 focus:outline focus-within:outline-2 focus-within:outline-cosmith-lavanda-500 rounded-sm'
            >
              info@cosmith.io
            </a>
            . If you are not satisfied with our response, you can file a
            complaint with the appropriate data protection authority.
          </Paragraph>
        </Section>

        <Section title='Updates to this Privacy Policy'>
          <Paragraph>
            We may update this Privacy Policy from time to time. The latest
            version will always be available on our website.
          </Paragraph>
          <Paragraph className='mt-4'>
            <StrongText>Date of Last Update: 09.10.2024</StrongText>
          </Paragraph>
        </Section>
      </div>
      <CallToAction />
    </div>
  );
};
