import { ColorPageHeader } from '../components/generic/header/ColorPageHeader';
import { ClientInfoSections } from '../components/help-page/ClientInfoSections';

export const HelpPage = () => {
  const crumbs = [
    { label: 'Home', to: '/' },
    { label: 'Help Center', to: '/help' },
  ];

  return (
    <section className='w-full'>
      <ColorPageHeader
        mainHeader='Help Articles'
        secondaryHeader='Manage and organize your automations'
        crumbs={crumbs}
      />
      <div className='help-content p-2 sm:p-10 md:p-6 max-w-screen-xl m-auto mt-4 sm:mt-12'>
        <ClientInfoSections />
      </div>
    </section>
  );
};
