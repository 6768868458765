import adalo from '../../assets/components/Adalo.png';
import bubble from '../../assets/components/Bubble.svg';
import flutter_flow from '../../assets/components/Flutter-flow.png';
import react from '../../assets/icons/reactIcon.svg';
import next from '../../assets/icons/nextjsIcon.svg';

const technologies = [
  { icon: bubble, width: '129px', height: '29px' },
  { icon: adalo, width: '119px', height: '43px' },
  { icon: flutter_flow, name: 'Flutter Flow', height: '50px' },
  { icon: react, name: 'React', height: '50px' },
  { icon: next, name: 'Next.js', height: '50px' },
  { icon: react, name: 'React Native', height: '50px' },
];
export const AboutUsTechnologies = () => {
  return (
    <section className='my-12 lg:my-[64px]'>
      <h2 className='text-[32px] font-semibold leading-[54px] tracking-[-0.032px] text-center'>
        Technologies
      </h2>

      <ul className='flex flex-wrap justify-center gap-6 sm:gap-[0.7px] lg:gap-[54.7px] mt-12 sm:mt-8'>
        {technologies.map(({ icon, name, height, width }) => (
          <li key={name} className='flex items-center gap-[10px]'>
            <img
              src={icon}
              alt={name}
              width={width || height}
              height={height}
            />
            <p className='font-black text-[20px] leading-[44.91px]'>{name}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};
