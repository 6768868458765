import { HeroTitle } from '../generic/landing/HeroTitle';
import { HeroText } from '../generic/landing/HeroText';
import { OverWorldSocial } from './OverWorldSocial';
import map from '../../assets/icons/Map.svg';

export const OverWorldSection = () => {
  return (
    <section className='flex flex-col items-center my-12 lg:my-[64px]'>
      <div className='max-w-[768px] text-center '>
        <HeroTitle>Our clients are all over the world</HeroTitle>
        <HeroText>
          We collaborate with companies in different corners of the world,
          providing high-quality business solutions.
        </HeroText>
      </div>
      <img
        src={map}
        width={1024}
        height={488}
        alt='map'
        className='hidden lg:block my-[64px]'
      />
      <OverWorldSocial />
    </section>
  );
};
