import { HelpSection } from '../components/talk-to-an-expert/HelpSection';
import { VideoSection } from '../components/talk-to-an-expert/VideoSection';
import { BetterThenOthers } from '../components/generic/landing/BetterThanOthers';
import { SectionWithSEO } from '../components/talk-to-an-expert/SectionWithSEO';
import { IdicatorsOfWork } from '../components/generic/indicatorsOfOurWork/IndicatorsOfWork';
import { WhatExpertCanDo } from '../components/talk-to-an-expert/WhatExpertCanDo';

export const TalkToAnExpert = () => {
  return (
    <>
      <HelpSection />
      <VideoSection />
      <BetterThenOthers />
      <SectionWithSEO />
      <IdicatorsOfWork />
      <WhatExpertCanDo />
    </>
  );
};
