import { GithubIcon } from '../../assets/icons-social/GithubIcon';
import { YoutubeIcon } from '../../assets/icons-social/YoutubeIcon';
import { youtubeLink, githubLink } from '../../appconfig';

const contactsInfo = [
  {
    title: 'Support',
    text: 'Our friendly team is here to help.',
    email: 'info@cosmith.io',
  },
  {
    title: 'Sales',
    text: 'Questions or queries? Get in touch!',
    email: 'info@cosmith.io',
  },
  {
    title: 'Social media',
    text: 'Follow us to stay updated on all our latest events and news.',
  },
];

export const OverWorldSocial = () => {
  return (
    <ul className='flex flex-wrap text-center justify-center gap-8'>
      {contactsInfo.map(({ title, text, email }, index) => (
        <li key={index} className='w-[370px] flex flex-col items-center'>
          <h3 className='text-center font-semibold text-xl leading-[30px]'>
            {title}
          </h3>
          <p className='text-center text-cosmith-primary-800 mb-5 mt-2'>
            {text}
          </p>

          {email ? (
            <a
              href={`mailto:${email}`}
              className='text-center font-semibold text-base focus-outline-custom'
            >
              {email}
            </a>
          ) : (
            <ul className='flex gap-4'>
              <li className='hover:scale-150 transition-transform duration-300 ease-out text-cosmith-primary-900'>
                <a
                  href={youtubeLink}
                  target='_blank'
                  rel='noreferrer'
                  className='focus:outline-cosmith-lavanda-400 '
                >
                  <YoutubeIcon fill='currentColor' opacity={1} />
                </a>
              </li>
              <li className='hover:scale-150 transition-transform duration-300 ease-out '>
                <a
                  href={githubLink}
                  target='_blank'
                  rel='noreferrer'
                  className='focus:outline-cosmith-lavanda-400 '
                >
                  <GithubIcon fill='currentColor' opacity={1} />
                </a>
              </li>
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};
