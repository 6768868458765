import { ColorPageHeader } from '../components/generic/header/ColorPageHeader';
import Section from '../components/generic/text-format/Section';
import Paragraph from '../components/generic/text-format/Paragraph';
import StyledList from '../components/generic/text-format/StyledList';
import StrongText from '../components/generic/text-format/StrongText';
import { CallToAction } from '../components/generic/landing/CallToAction';

export const TermsOfUsePage = () => {
  return (
    <>
      <ColorPageHeader
        mainHeader='Terms of Use'
        secondaryHeader='Understand your rights and responsibilities while using our services'
        showSearch
      />

      <div className='p-2 sm:p-10 md:p-6 max-w-screen-xl m-auto'>
        <Section title='Acceptance of Terms'>
          <Paragraph>
            By accessing and using this website, you agree to comply with and be
            bound by these <StrongText>Terms of Use</StrongText>. If you do not
            agree to these terms, please do not use the website.
          </Paragraph>
        </Section>

        <Section title='Changes to Terms'>
          <Paragraph>
            We may update these <StrongText>Terms of Use</StrongText> from time
            to time without prior notice. Continued use of the website implies
            acceptance of the revised terms.
          </Paragraph>
        </Section>

        <Section title='User Responsibilities'>
          <StyledList
            items={[
              'Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.',
              'You agree not to use the site for any unlawful purpose.',
            ]}
          />
        </Section>

        <Section title='Intellectual Property'>
          <Paragraph>
            All content on this website, including text, images, graphics, and
            logos, is the property of the website owner or its licensors.
            Unauthorized use is prohibited.
          </Paragraph>
        </Section>

        <Section title='Limitations of Liability'>
          <Paragraph>
            We are not liable for any direct, indirect, incidental, or
            consequential damages arising from the use or inability to use this
            website.
          </Paragraph>
        </Section>

        <Section title='Governing Law'>
          <Paragraph>
            These <StrongText>Terms of Use</StrongText> are governed by and
            construed in accordance with the laws of Ukraine. Any disputes will
            be subject to the exclusive jurisdiction of the courts in Ukraine.
          </Paragraph>
        </Section>

        <Section title='Contact Information'>
          <Paragraph>
            If you have any questions regarding these terms, please contact us
            at{' '}
            <a
              href='mailto:info@cosmith.io'
              className='text-blue-600 focus:outline focus-within:outline-2 focus-within:outline-cosmith-lavanda-500 rounded-sm'
            >
              info@cosmith.io
            </a>
            .
          </Paragraph>
        </Section>
        <CallToAction />
      </div>
    </>
  );
};
