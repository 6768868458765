import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { IdicatorsOfWork } from '../generic/indicatorsOfOurWork/IndicatorsOfWork';
import AboutUsIndicators_tablet from '../../assets/aboutUs/AboutUsIndicators_tablet.png';
import AboutUsIndicators_pc from '../../assets/aboutUs/AboutUsIndicators_pc.png';
import AboutUsIndicators_mobile from '../../assets/aboutUs/AboutUsIndicators_mobile.png';

const images = {
  mobile: {
    src: AboutUsIndicators_mobile,
    width: 441,
  },
  tablet: {
    src: AboutUsIndicators_tablet,
    width: 588,
  },
  pc: {
    src: AboutUsIndicators_pc,
    width: 588,
  },
};

export const AboutUsIndicators = () => {
  return (
    <div className='pt-6 pb-12 lg:p-0 lg:ml-[-2rem] xl:ml-0'>
      <AdaptiveImageCard
        reversed
        secondChildren={
          <ResponsivePicture
            imageUrl={AboutUsIndicators_pc}
            images={images}
            className="reletive flex items-center 
                          after:content-[' '] after:h-[358px] after:w-[358px]
                          after:absolute after:bg-[url('/src/assets/BackgroundRight.svg')] after:bg-no-repeat after:right-0 after:bg-left
                          sm:after:h-[588px] sm:after:w-[588px]
                          lg:after:bg-none 
                          xl:after:bg-[url('/src/assets/BackgroundLeft.svg')] xl:after:left-0
                          xl:after:ml-[-8rem] xl:after:bg-right"
          />
        }
      >
        <IdicatorsOfWork gridClassName='grid-cols-2' />
      </AdaptiveImageCard>
    </div>
  );
};
