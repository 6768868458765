import adalo from '../../assets/components/Adalo.png';
import bubble from '../../assets/components/Bubble.svg';
import flutter_flow from '../../assets/components/Flutter-flow.png';

export const TrustedBy = () => {
  return (
    <section className='mt-4 lg:mt-6 xl:mt-16'>
      <p className='mb-6  text-center opacity-60 tracking-[0.01rem] lg:text-left'>
        Trusted by the biggest companies
      </p>
      <div className='flex justify-center gap-6 pl-3 items-center lg:pl-0 lg:justify-start'>
        <img src={bubble} alt='bubble logo' />
        <img src={adalo} alt='adalo logo' width={111} />

        <div className='flex pt-2.5 pb-2.5'>
          <div className='mt-[-1px] h-[18px] w-[18px]'>
            <img
              className='inline-block'
              src={flutter_flow}
              alt='flutter flow logo'
            />
          </div>

          <p className='text-[10.861px] font-black'>FlutterFlow</p>
        </div>
      </div>
    </section>
  );
};
